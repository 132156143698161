export const GET_PROPS_REQUEST = 'GET_PROPS_REQUEST';
export const GET_PROPS_SUCCESS = 'GET_PROPS_SUCCESS';
export const GET_PROPS_FAIL = 'GET_PROPS_FAIL';

export const UPDATE_PROPS_REQUEST = 'UPDATE_PROPS_REQUEST';
export const UPDATE_PROPS_SUCCESS = 'UPDATE_PROPS_SUCCESS';
export const UPDATE_PROPS_FAIL = 'UPDATE_PROPS_FAIL';

export const GET_PROP_DETAILS_REQUEST = 'GET_PROP_DETAILS_REQUEST';
export const GET_PROP_DETAILS_SUCCESS = 'GET_PROP_DETAILS_SUCCESS';
export const GET_PROP_DETAILS_FAIL = 'GET_PROP_DETAILS_FAIL';
export const GET_PROP_DETAILS_RESET = 'GET_PROP_DETAILS_RESET';
